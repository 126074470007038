// src/About.js
import React from 'react';
import Layout from '../components/Layout';

const About = () => {
  return (
    <Layout>
    <div className="max-w-md mx-auto mt-8 bg-white p-6 rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Tentang PWA</h2>
      <p className="text-lg text-gray-800 overscroll-contain">
      Progressive Web Apps (PWA) adalah revolusi dalam dunia aplikasi web, yang menggabungkan keunggulan dari situs web dan aplikasi seluler. Dibangun dengan teknologi web terbaru, PWA memberikan pengalaman pengguna yang lebih baik, lebih cepat, dan lebih interaktif, mirip dengan aplikasi native pada perangkat seluler dan desktop.
      </p>

      <p className="text-lg text-gray-800">
      PWA berfungsi secara progresif, artinya mereka dapat berjalan pada semua perangkat dan browser modern. Namun, mereka menyajikan pengalaman yang lebih maju pada perangkat yang mendukung fitur-fitur PWA. Berkat kemampuan mereka untuk menyimpan data di cache perangkat, PWA dapat diakses bahkan ketika pengguna tidak terhubung ke internet, mengatasi salah satu kelemahan utama aplikasi web tradisional.
      </p>
    </div>
    </Layout>
  );
};

export default About;
