import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CgEye, CgTrash } from 'react-icons/cg';
import { FaCheck, FaTimes } from 'react-icons/fa';
import Swal from 'sweetalert2';
import iconPetugas from '../assets/images/icon-petugas.png';
import iconKapal from '../assets/images/icon-kapal.png';
import iconKDO from '../assets/images/icon-kdo.png';
import iconBusUPAS from '../assets/images/icon-BusUPAS.png';
import '../ViewAllUsers.css';

const userID = localStorage.getItem('IDs');

const ViewAllUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    nama: '',
    jenis: '',
    satuan: '',
    role: ''
  });
  const [filterOptions, setFilterOptions] = useState({
    jenis: [],
    satuan: [],
    role: []
  });

  const handleViewUser = (userID) => {
    navigate(`/profile/${userID}`);
  };

  const handleDeleteUser = async (userID) => {
    Swal.fire({
      title: 'Konfirmasi Menghapus Profile',
      text: 'Anda yakin akan menghapus profile?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Hapus',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`https://jaktraffic.my.id/geoapi/user/delete/${userID}`);
          localStorage.clear();
          Swal.fire({
            title: 'Profile Berhasil Dihapus',
            text: 'Anda telah menghapus profile',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.reload(); // Refresh halaman setelah menghapus
          });
        } catch (error) {
          console.error('Error deleting profile:', error);
        }
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://jaktraffic.my.id/geoapi/user/viewu/${userID}`);
        const usersData = response.data;

        const uniqueJenis = [...new Set(usersData.map(user => user.Jenis))];
        const uniqueSatuan = [...new Set(usersData.map(user => user.Satuan))];
        const uniqueRole = [...new Set(usersData.map(user => user.Role))];

        setUsers(usersData);
        setFilterOptions({
          jenis: uniqueJenis,
          satuan: uniqueSatuan,
          role: uniqueRole
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const filteredUsers = users.filter(user => {
    return (
      (filters.nama === '' || user.Nama.toLowerCase().includes(filters.nama.toLowerCase())) &&
      (filters.jenis === '' || user.Jenis === filters.jenis) &&
      (filters.satuan === '' || user.Satuan === filters.satuan) &&
      (filters.role === '' || user.Role === filters.role)
    );
  });

  if (loading) {
    return <Layout>Loading...</Layout>;
  }

  if (error) {
    return <Layout>{error}</Layout>;
  }

  return (
    <Layout>
      <div className="p-4">
        <h2 className="text-2xl font-bold dark:text-white text-center mb-4">Daftar User</h2>
        <br />
        <div className="filter-container flex items-center space-x-4 mb-4">
          <input
            type="text"
            name="nama"
            value={filters.nama}
            onChange={handleFilterChange}
            placeholder="Cari Nama"
            className="filter-select p-2 border rounded"
          />
          <select name="jenis" value={filters.jenis} onChange={handleFilterChange} className="filter-select p-2 border rounded">
            <option value="">All Jenis</option>
            {filterOptions.jenis.map(jenis => (
              <option key={jenis} value={jenis}>{jenis}</option>
            ))}
          </select>
          <select name="satuan" value={filters.satuan} onChange={handleFilterChange} className="filter-select p-2 border rounded">
            <option value="">All Satuan</option>
            {filterOptions.satuan.map(satuan => (
              <option key={satuan} value={satuan}>{satuan}</option>
            ))}
          </select>
          <select name="role" value={filters.role} onChange={handleFilterChange} className="filter-select p-2 border rounded">
            <option value="">All Roles</option>
            {filterOptions.role.map(role => (
              <option key={role} value={role}>{role}</option>
            ))}
          </select>
        </div>
        <div className="overflow-x-auto">
          <div className="max-h-96 overflow-y-auto">
            {filteredUsers.length === 0 ? (
              <div className="text-center dark:text-white">Data not found</div>
            ) : (
              <table className="table-auto w-full border-collapse border-2">
                <thead className="sticky top-0 bg-gray-200 text-gray-800">
                  <tr>
                    <th className="border p-2 font-semibold">No</th>
                    <th className="border p-2 font-semibold">UserID</th>
                    <th className="border p-2 font-semibold">NRK/ID PJLP</th>
                    <th className="border p-2 font-semibold">Nama</th>
                    <th className="border p-2 font-semibold">Email</th>
                    <th className="border p-2 font-semibold">Jenis</th>
                    <th className="border p-2 font-semibold">Satuan</th>
                    <th className="border p-2 font-semibold">Role</th>
                    <th className="border p-2 font-semibold">UniqueCode</th>
                    <th className="border p-2 font-semibold">Status</th>
                    <th className="border p-2 font-semibold">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map((user, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-white-100' : ''}>
                      <td className="border p-2 dark:text-white">{index + 1}</td>
                      <td className="border p-2 dark:text-white">{user.userID}</td>
                      <td className="border p-2 dark:text-white">{user.NRKorNIP}</td>
                      <td className="border p-2 dark:text-white">{user.Nama}</td>
                      <td className="border p-2 dark:text-white">{user.Email}</td>
                      <td className="border p-2 dark:text-white">{user.Jenis}</td>
                      <td className="border p-2 dark:text-white">{user.Satuan}</td>
                      <td className="border p-2 dark:text-white">{user.Role}</td>
                      <td className="border p-2 dark:text-white">{user.UniqueCode}</td>
                      <td className="border p-2 dark:text-white">
                        {user.isActive ? <FaCheck style={{ color: 'green' }} /> : <FaTimes style={{ color: 'red' }} />}
                      </td>
                      <td className="border p-2 dark:text-white">
                        <button onClick={() => handleViewUser(user.userID)}>
                          <CgEye />
                        </button>
                        <button onClick={() => handleDeleteUser(user.userID)}>
                          <CgTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <div>
          <h2 className="text-md font-bold dark:text-white text-left mb-2 mt-5">Keterangan</h2>
          <table className="table-auto w-60 border-collapse border-2">
            <thead className="sticky top-0 dark:text-white dark:bg-gray-800">
              <tr>
                <th className="border p-2 dark:text-white font-semibold">Jenis</th>
                <th className="border p-2 dark:text-white font-semibold">Icon</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border p-2 dark:text-white">Kapal</td>
                <td className="border p-2 dark:text-white">
                  <img src={iconKapal} alt="iconKapal" className="w-6 h-6" />
                </td>
              </tr>
              <tr>
                <td className="border p-2 dark:text-white">KDO</td>
                <td className="border p-2 dark:text-white">
                  <img src={iconKDO} alt="iconKDO" className="w-6 h-6" />
                </td>
              </tr>
              <tr>
                <td className="border p-2 dark:text-white">Petugas</td>
                <td className="border p-2 dark:text-white">
                  <img src={iconPetugas} alt="iconPetugas" className="w-6 h-6" />
                </td>
              </tr>
              <tr>
                <td className="border p-2 dark:text-white">Bus Sekolah</td>
                <td className="border p-2 dark:text-white">
                  <img src={iconBusUPAS} alt="iconBusUPAS" className="w-6 h-6" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default ViewAllUsers;
