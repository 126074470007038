import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import logoImage from '../assets/images/loc.gif';
import Swal from 'sweetalert2';
import Layout from '../components/Layout';

const UserProfile = () => {
  // Menggunakan state untuk menyimpan data profile pengguna
  const [profile, setProfile] = useState({
    NRKorNIP: '',
    Nama: '',
    Email: '',
    Wilayah: '',
    Satuan: '',
    Role: '',
    userID: '',
    UniqueCode: ''
  });

  // Mendapatkan userID dari penyimpanan lokal (localstorage)
  const userID = localStorage.getItem('IDs');
  console.log(userID);
  const apiUrl = `https://jaktraffic.my.id/geoapi/user/view/${userID}`;

  // Fungsi untuk menghapus profile pengguna
  const handleDelete = () => {
    Swal.fire({
      title: 'Konfirmasi Menghapus Profile',
      text: 'Anda yakin akan menghapus profile?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Hapus',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(apiUrl);
          localStorage.clear();
          Swal.fire({
            title: 'Profile Berhasil Dihapus',
            text: 'Anda telah menghapus profile',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.href = '/Profile'; // Ganti dengan path menuju halaman login yang sesuai
          });
        } catch (error) {
          console.error('Error deleting profile:', error);
        }
      }
    });
  };

  // Mengambil profile pengguna dengan permintaan HTTPS GET saat komponen dimuat
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(apiUrl);
        setProfile(response.data);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, [apiUrl]);

  return (
    <Layout>
      <div className="flex justify-center items-center h-screen">
        <div className="bg-white p-2 rounded-lg shadow-md">

          <div className="flex items-center mb-4 justify-between">
            <div className="flex items-center">

              <img
                src={logoImage}
                alt="Logo"
                className="w-16 h-16 rounded-full mr-4"
                style={{ width: '100px', height: 'auto' }}
              />
              <div className='text-start'>
                <div className='mb-2'>
                  <p className="font-semibold">Nama</p>
                  {profile.Nama}
                </div>
                <div className='mb-2'>
                  <p className="font-semibold">Userid</p>
                  {profile.userID}
                </div>
              </div>
            </div>
            <div className="grid grid-rows-2 gap-4">
              <Link to="/EditProfile" className="col-span-1 bg-blue-500 text-white px-4 py-2 mr-4 rounded font-medium">
                Edit Profile
              </Link>
              <button onClick={handleDelete} className="col-span-1 bg-red-500 text-white px-4 py-2 mr-4 rounded font-medium">
                Hapus
              </button>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4 mb-4 bg-blue-900 text-white p-4 rounded-lg" style={{ width: '100%', height: '200px' }}>
            {/* Konten di dalamnya */}
            <div className='mb-2'>
              <p className="font-semibold">Role:</p>
              {profile.Role}
            </div>
            <div>
              <p className="font-semibold">NRP or NIP:</p>
              {profile.NRKorNIP}
            </div>
            <div>
              <p className="font-semibold">Satuan:</p>
              {profile.Satuan}
            </div>
            <div>
              <p className="font-semibold">Unique Code:</p>
              {profile.UniqueCode}
            </div>
            <div>
              <p className="font-semibold">Wilayah:</p>
              {profile.Wilayah}
            </div>
            <div>
              <p className="font-semibold">Email:</p>
              {profile.Email}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UserProfile;
