import React from 'react';

const Bottom = () => {
  return (
    <div className="fixed bottom-0 left-0 dark:text-white text-right w-full dark:bg-gray-800 p-1">
      &copy; 2023. Pusat Data Dan Informasi Dinas Perhubungan Provinsi DKI Jakarta. All rights reserved.
    </div>
  );
};

export default Bottom;