import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Layout from '../components/Layout';

const EditProfile = () => {
  // State untuk menyimpan data profile pengguna
    const [user, setUser] = useState({
        "NRKorNIP": "1000",
        "Nama": "Naufal",
        "Email": "naugans@dishub.go.id",
        "Wilayah": "Provinsi",
        "Satuan": "SIM",
        "Role": "Master",
        "userID": "P10501",
        "UniqueCode": "10359"
    });

    // Fungsi untuk menangani pembaruan profile pengguna
    const handleUpdateProfile = () => {
        Swal.fire({
            title: 'Update Profile?',
            text: 'Are you sure you want to update your profile?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            customClass: {
                confirmButton: 'bg-blue-500 text-white px-4 py-2 rounded',
                cancelButton: 'text-gray-500 px-4 py-2 rounded',
                title: 'text-xl font-semibold',
                content: 'text-base',
            },
            center: true,
        }).then((result) => {
            if (result.isConfirmed) {
                // Proses update profile ke backend atau lakukan tindakan sesuai kebutuhan
                Swal.fire({
                    title: 'Updated',
                    text: 'Your profile has been updated successfully!',
                    icon: 'success',
                    customClass: {
                        confirmButton: 'bg-blue-500 text-white px-4 py-2 rounded',
                        title: 'text-xl font-semibold',
                        content: 'text-base',
                    },
                    center: true,
                });
            }
        });
    };

    // Fungsi untuk mengubah input profile
    const handleInputChange = (key, value) => {
        setUser(prevUser => ({
            ...prevUser,
            [key]: value
        }));
    };

    return (
      <Layout>
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="w-full max-w-md p-8 bg-white rounded shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Edit Profile</h2>
          <div className="overflow-y-auto max-h-96"> {/* Scrollable container */}
            <form>
              {Object.keys(user).map((key) => (
                <div key={key} className="mb-4">
                  <label className="font-semibold mb-2">{key}:</label>
                  <input
                    type="text"
                    value={user[key]}
                    className={`w-full bg-gray-200 p-2 rounded ${
                      key === 'Satuan' || key === 'Role' || key === 'userID' || key === 'UniqueCode'
                        ? 'bg-gray-100'
                        : ''
                    }`}
                    readOnly={key === 'Satuan' || key === 'Role' || key === 'userID' || key === 'UniqueCode'}
                    onChange={(e) => handleInputChange(key, e.target.value)}
                  />
                </div>
              ))}
              <button
                type="button"
                onClick={handleUpdateProfile}
                className="w-full bg-blue-500 text-white py-2 px-4 rounded"
              >
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
    );
};

export default EditProfile;
