import React from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'

const Layout = ({ children }) => {
    return (
        <>
            <div className='flex flex-auto h-screen '>
                <Sidebar />
                <div className='grow'>
                    <Navbar />
                    <div className='min-h-screen bg-gray-100 dark:bg-gray-800'>{children}</div>
                </div>
            </div>
        </>
    )
}

export default Layout
