
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import HomePage from './pages/HomePage'
import About from './pages/about'
import Bottom from './pages/bottom'
import Dashboard from './pages/Dashboard'

import Profile from './pages/Profile'
import EditProfile from './pages/EditProfile'
import Login from './pages/login'
import Register from './pages/Register'
import Logout from './pages/logout'
import MapWithGeolocation from './pages/MapWithGeolocation'
import ResetPassword from './pages/ResetPassword'
import ViewAllUsers from './pages/ViewAllUsers'
import MapTransjakarta from './pages/MapTransjakarta'
import Newarea from './pages/newaarea'
import Newuser from './pages/newuser'
import Area from './pages/areas'
function App() {
    return (

        <BrowserRouter>


            <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path="/HomePage" element={<HomePage />} />
                <Route path="/about" element={<About />} />

                <Route path='/profile/:userID' element={<Profile />} />
                <Route path="/ViewAllUsers" element={<ViewAllUsers />} />
                <Route path="/Register" element={<Register />} />
                <Route path="/EditProfile" element={<EditProfile />} />
                <Route path="/newarea" element={<Newarea />} />
                <Route path="/newuser" element={<Newuser />} />
                <Route path="/areas" element={<Area />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/MapWithGeolocation" element={<MapWithGeolocation />} />
                <Route path="/MapTransjakarta" element={<MapTransjakarta />} />
                <Route path="/logout" element={<Logout />} />


            </Routes>

            <Bottom />
        </BrowserRouter>
    )
}

export default App
