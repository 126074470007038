import React, { useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

const ResetPassword = () => {
  // State untuk menyimpan data yang dimasukkan oleh pengguna
  const [userID, setUserID] = useState(''); // UserID
  const [uniqueCode, setUniqueCode] = useState(''); // Unique Code
  const [newPassword, setNewPassword] = useState(''); // Kata sandi baru

  // Fungsi untuk meng-handle pengiriman permintaan reset password
  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      // URL untuk mengirim permintaan reset password
      const resetEndpoint = 'https://jaktraffic.my.id/geoapi/usermbl/res'; // URL reset password
      const requestBody = {
        userID: userID, // UserID dari input
        UniqueCode: uniqueCode, // Unique code dari input
        Password: newPassword // Kata sandi baru dari input
      };

      // Kirim permintaan PUT ke API
      const response = await axios.put(resetEndpoint, requestBody);

      // Handle respon dari API
      if (response.data.message === 'Reset Password Success') {
        Swal.fire({
          title: 'Tautan Dikirim',
          text: 'Password berhasil direset.',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          title: 'Kesalahan',
          text: 'Terjadi kesalahan saat mereset password.',
          icon: 'error',
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK',
        });
      }

    } catch (error) {
      // Handle kesalahan yang mungkin terjadi selama pengiriman permintaan
      Swal.fire({
        title: 'Kesalahan',
        text: 'Terjadi kesalahan. Silakan coba lagi nanti.',
        icon: 'error',
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK',
      });
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 via-lightblue-500 to-red-500">
      <div className="w-96 bg-white p-6 rounded shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-center">Reset Password</h2>
        <form onSubmit={handleResetPassword}>
          <div className="mb-4">
            <label htmlFor="userID" className="block text-sm font-medium text-gray-700">
              UserID
            </label>
            <input
              type="text"
              id="userID"
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Masukkan UserID Anda"
              value={userID}
              onChange={(e) => setUserID(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="uniqueCode" className="block text-sm font-medium text-gray-700">
              Kode Unik
            </label>
            <input
              type="UniqueCode"
              id="uniqueCode"
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Masukkan kode unik Anda"
              value={uniqueCode}
              onChange={(e) => setUniqueCode(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
              Kata Sandi Baru
            </label>
            <input
              type="password"
              id="newPassword"
              className="mt-1 p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Masukkan kata sandi baru Anda"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded w-full font-medium focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Reset Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
