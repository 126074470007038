import React, { useEffect, useState, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "tailwindcss/tailwind.css";
import L from "leaflet";
import axios from "axios";
import iconTJ from "../assets/images/icon-tj.png";
import iconKwk from "../assets/images/icon-kwk.png";
import Layout from "../components/Layout";
import { CountUp } from "countup.js";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";

const MapTransjakarta = () => {
  // State untuk mentimpan posisi pengguna pada peta
  const [userPosition, setUserPosition] = useState(null);

  // State untuk menyimpan data lokasi dan jumlah Transjakarta (TJ) dan Mikrotrans
  const [LocationTJ, setLocationTJ] = useState([]);
  const [LocationKWK, setLocationKWK] = useState([]);
  const [countTJ, setCountTJ] = useState(0);
  const [countKWK, setCountKWK] = useState(0);

  //Ref untuk mentimpan instance CountUp untuk animasi perubahan jumlah
  const countTJRef = useRef(null);
  const countKWKRef = useRef(null);

  // Fungsi untuk refresh map dengan data lokasi
  const refreshMap = async () => {
    try {
      // Ambil data lokasi Transjakarta (TJ) dan Mikrotrans dari API
      const TJ = await axios.get("https://jaktraffic.my.id/geoapi/gps/tj", {
        params: {
          startTimestamp: new Date(new Date() - 15 * 1000).toISOString(),
        },
      });

      const KWK = await axios.get("https://jaktraffic.my.id/geoapi/gps/kwk", {
        params: {
          startTimestamp: new Date(new Date() - 15 * 1000).toISOString(),
        },
      });

      // Perbarui state dengan data lokasi dan jumlah
      setLocationTJ([...TJ.data.log]);
      setLocationKWK([...KWK.data.log]);
      setCountTJ(TJ.data.count);
      setCountKWK(KWK.data.count);

      // Buat dan mulai animasi CountUp untuk jumlah TJ dan Mikrotrans
      const countUpTJ = new CountUp(countTJRef.current, TJ.data.count);
      const countUpKWK = new CountUp(countKWKRef.current, KWK.data.count);

      if (!countUpTJ.error && !countUpKWK.error) {
        countUpTJ.start();
        countUpKWK.start();
      } else {
        console.error("Error initializing CountUp animations.");
      }
    } catch (error) {
      console.error("Error fetching data from the API:", error);
    }
  };

  // Gunakan geolokasi untuk mengambil dan mengatur lokasi pengguna
  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const { latitude, longitude } = position.coords;
        setUserPosition([latitude, longitude]);
      } catch (error) {
        console.error("Error getting geolocation:", error);
        alert(
          "Error getting geolocation. Mohon izinkan akses lokasi dan coba lagi."
        );
      }
    };

    fetchUserLocation();
  }, []);

  // Refresh map pada awalnya dan dengan interval teratur
  useEffect(() => {
    refreshMap();

    // Atur interval untuk refresh map setiap 10 detik
    const intervalId = setInterval(refreshMap, 3 * 60 * 1000);

    // Bersihkan interval ketika komponen tidak digunakan lagi
    return () => clearInterval(intervalId);
  }, []);

  // Periksa keberadaan token, dan alihkan jika tidak ada
  const token = localStorage.getItem("token");
  if (!token) {
    window.location.href = "/";
  }

  // Render map dan informasi terkait
  return (
    <Layout>
      <div>
        <h1 className="text-2xl text-center dark:text-white font-bold mb-4">
          Lokasi Transjakarta dan Mikrotrans
        </h1>
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center mb-4">
            <div className="mr-4">
              <h3 className="text-5xl font-extrabold leading-tight text-center dark:text-white font-bold mb-2">
                <span ref={countTJRef}></span>
              </h3>
              <p className="text-base font-medium leading-7 text-center dark:text-white font-bold mb-4">
                Bus Transjakarta
              </p>
            </div>
            <div>
              <h3 className="text-5xl font-extrabold leading-tight text-center dark:text-white font-bold mb-2">
                <span ref={countKWKRef}></span>
              </h3>
              <p className="text-base font-medium leading-7 text-center dark:text-white font-bold mb-4">
                Mikrotrans
              </p>
            </div>
          </div>
        </div>
        <div className=" h-screen items-center justify-center">
          <MapContainer
            center={userPosition || [-6.1754, 106.8272]}
            zoom={13}
            style={{ width: "80%", height: "75%", marginLeft: "120px" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <FullscreenControl />
            {LocationTJ.map((location, index) => (
              <Marker
                key={index}
                position={[location.latitude, location.longitude]}
                icon={L.icon({ iconUrl: iconTJ, iconSize: [30, 30] })}
              >
                <Popup>
                  <div>
                    <h2 className="text-lg font-semibold mb-2">Info TJ : </h2>
                    <p>
                      <strong>Bus Code:</strong> {location.bus_code}
                    </p>
                    <p>
                      <strong>Koridor:</strong> {location.koridor}
                    </p>
                    <p>
                      <strong>Trip:</strong> {location.trip_desc}
                    </p>
                    <p>
                      <strong>Gps Speed:</strong> {location.gpsspeed} Km/h
                    </p>
                  </div>
                </Popup>
              </Marker>
            ))}

            {LocationKWK.map((location, index) => (
              <Marker
                key={index}
                position={[location.latitude, location.longitude]}
                icon={L.icon({ iconUrl: iconKwk, iconSize: [24, 24] })}
              >
                <Popup>
                  <div>
                    <h2 className="text-lg font-semibold mb-2">Info Mikrotrans : </h2>
                    <p>
                      <strong>Nopol / Lambung:</strong> {location.idkend}
                    </p>
                    <p>
                      <strong>Rute:</strong> {location.route}
                    </p>
                    <p>
                      <strong>Gps Speed:</strong> {location.speed} Km/h
                    </p>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
    </Layout>
  );
};

export default MapTransjakarta;
