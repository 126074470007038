import React from 'react'
import Toggle from './ThemeToggle'
import 'tailwindcss/tailwind.css';

const Navbar = () => {
  return (
    <nav className='bg-white border-gray-200 mx-2 px-2 py-2.5 rounded dark:bg-gray-800'>
      <div className='container flex justify-between items-center mx-auto pt-3'>
        <div className='flex items-center mx-auto'>
          <span className='text-xl font-medium whitespace-nowrap dark:text-white'>
            
          </span>
        </div>
        <div className='flex justify-end pr-4'>
          <Toggle />
        </div>
        
        <nav>
        <ul className="flex space-x-4">
          <li>
            <a href="/HomePage" className="dark:text-white hover:text-gray-300">
              Home
            </a>
          </li>
          <li>
            <a href="./about" className="dark:text-white hover:text-gray-300">
              About
            </a>
          </li>
        </ul>
      </nav>
      
      </div>
    </nav>
  )
}

export default Navbar
