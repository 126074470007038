import React from 'react';
import Layout from '../components/Layout';

const HomePage = () => {
  // Data dummy
  const totalLaporan = 20;
  const totalStaff = 20;
  const staffTimA = 3;
  const staffTimB = 2;
  const staffIzin = 10;
  const staffCuti = 5;

  const listStaffTimA = [
    { id: 1, name: 'Ripan' },
    { id: 2, name: 'Janet' },
    { id: 3, name: 'Banet' },
    // Tambahkan data lainnya sesuai kebutuhan
  ];

  const listStaffTimB = [
    { id: 3, name: 'Bobi' },
    { id: 4, name: 'Alice' },
    // Tambahkan data lainnya sesuai kebutuhan
  ];

  return (
    <Layout>
      <div className="p-4">
        <h2 className="text-2xl font-bold dark:text-white mb-4">Beranda</h2>
        <h1 className="text-2xl font-bold dark:text-white mt-4 text-center">Selamat Datang di GeoMap</h1>
        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-white p-4 rounded shadow-md">
          <p className="text-gray-600 mb-2">Total Laporan: {totalLaporan}</p>
          <p className="text-gray-600 mb-2">Total Staff: {totalStaff}</p>
          <p className="text-gray-600 mb-2">Staff Tim A: {staffTimA}</p>
          <p className="text-gray-600 mb-2">Staff Tim B: {staffTimB}</p>
          <p className="text-gray-600 mb-2">Staff Izin: {staffIzin}</p>
          <p className="text-gray-600 mb-2">Staff Cuti: {staffCuti}</p>
        </div>
        <div className="bg-white p-4 rounded shadow-md">
          <div className="mb-2 font-bold">Daftar Nama Staff Tim A:</div>
          <ul>
            {listStaffTimA.map((staff) => (
              <li key={staff.id} className="text-gray-600">{staff.name}</li>
            ))}
          </ul>
          <div className="mt-4 mb-2 font-bold">Daftar Nama Staff Tim B:</div>
          <ul>
            {listStaffTimB.map((staff) => (
              <li key={staff.id} className="text-gray-600">{staff.name}</li>
            ))}
          </ul>
        </div>
      </div> */}
      </div>
    </Layout>
  );
};

export default HomePage;
