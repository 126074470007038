import React from 'react';
import Swal from 'sweetalert2';

const Logout = () => {
  const handleLogout = () => {
    // Tampilkan SweetAlert untuk konfirmasi logout
    Swal.fire({
      title: 'Konfirmasi Logout',
      text: 'Anda yakin akan logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Logout',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        // Hapus data pendaftaran dari local storage atau lakukan tindakan logout lainnya
        localStorage.clear();

        // Tampilkan SweetAlert "Logout berhasil"
        Swal.fire({
          title: 'Logout Berhasil',
          text: 'Anda telah berhasil logout.',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK',
        }).then(() => {
          // Mengalihkan ke halaman login setelah logout berhasil
          window.location.href = '/'; // Ganti dengan path menuju halaman login yang sesuai
        });
      }
    });
  };

  return (
    <div className="p-4">
      <button
        onClick={handleLogout}
        className="bg-red-500 text-white px-4 py-2 rounded font-medium"
      >
        Logout
      </button>
    </div>
  );
};

export default Logout;
